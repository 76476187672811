<template>
  <div>
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <a-row>
          <a-form-item class="ui-form__item" label="影片编码" name="standardCode">
            <a-input v-model:value="formState.standardCode" placeholder="请输入影片编码"></a-input>
          </a-form-item>
          <a-form-item class="ui-form__item" label="电影名称" name="filmName">
            <a-input v-model:value="formState.filmName" placeholder="请输入电影名称"></a-input>
          </a-form-item>
          <a-form-item class="ui-form__item" label="人员" name="actorName">
            <a-input v-model:value="formState.actorName" placeholder="请输入人员"></a-input>
          </a-form-item>
          <a-form-item class="ui-form__item" label="角色" name="position">
            <a-input v-model:value="formState.position" placeholder="请输入角色"></a-input>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
            <a-button v-permission="['film_performerInfo_performer_export']" type="link" style="background: rgb(26, 182, 103); color: white;">导 出</a-button>
          </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>

      <div style="margin-top: 20px;">
        <a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list">
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'index'">
              {{ index + 1 }}
            </template>
            <template v-if="column.key === 'actorImageUrl'">
              <a-image style="width: 60px;" :src="record.actorImageUrl"></a-image>
            </template>
          </template>
        </a-table>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {getFilmActorList} from "@/service/modules/report";

export default {
  components: {  },
  data() {
    return {
      loading: false,
      searchData: {},
      formState: {

      },
      list: [],
      columns: [
        {
          title: '序号',
          key: 'index',
        },
        {
          title: '影片编码',
          dataIndex: 'standardCode',
        },
        {
          title: '电影名称',
          dataIndex: 'filmName',
        },
        {
          title: '人员',
          dataIndex: 'actorName',
        },
        {
          title: '角色',
          dataIndex: 'position',
        },
        {
          title: '图片',
          key: 'actorImageUrl',
        },
      ],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  created() {
    this.getData();
  },
  methods: {
    reset(){
      this.$refs.form.resetFields();
      this.onSearch();
    },
    onSearch(){
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getFilmActorList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data.list;
          this.pagination.total = ret.data.totalCount;
        }
      }catch(e) {
        this.loading = false;
      }
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
